.title_div {
  /* background-color: #ed5b15; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
  font-family: initial;
  font-size: 2rem;
  padding: 10% 12%;
  background-image: url("../img/bg/bg_19.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* margin-top: 1%; */
  border-radius: 5px;
  margin: 2%;
  background-position-y: 80%;
  filter: invert();
}
.body_div {
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 5%;
  padding: 2% 10%;
  text-align: start;
  list-style: circle;
}
.data_div {
  padding: 5% 0% 5% 0%;
}
.head_div {
  font-size: x-large;
  font-weight: 700;
  color: brown;
  padding: 2%;
}
.content_div {
  font-family: "Times New Roman", Times, serif;
  padding: 2%;
}
.content_div ul {
  list-style: disc;
  padding: 4%;
}
@media only screen and (max-width: 550px) {
    .title_div{
        font-size: 1.3rem;
    }
  .body_div {
    grid-template-columns: 100%;
  }
}
